/**
 * Mixin for phone input formatting.
 * Provides computed properties `cleanedPhoneNumber` and `formattedPhoneNumber`.
 * Provides `cleanPhoneNumber` method.
 * @mixin phoneInputFormattingMixin
 */
export default {
  computed: {
    cleanedPhoneNumber() {
      return this.cleanPhoneNumber(this.phoneNumber);
    },
    formattedPhoneNumber() {
      if (this.activeDialCode !== '+55') return this.cleanedPhoneNumber;

      let phoneNumberWithMask = this.cleanedPhoneNumber
        .replace(/^(\d)/, '($1')
        .replace(/^(\(\d{2})(\d)/, '$1) $2');

      if (this.cleanedPhoneNumber.length <= 10) {
        phoneNumberWithMask = phoneNumberWithMask.replace(
          /(\d{4})(\d{1,4})/,
          '$1-$2'
        );
      } else {
        phoneNumberWithMask = phoneNumberWithMask.replace(
          /(\d{5})(\d{1,4})/,
          '$1-$2'
        );
      }

      return phoneNumberWithMask;
    },
  },
  methods: {
    cleanPhoneNumber(phoneNumber) {
      return phoneNumber.replace(/\D/g, '');
    },
  },
};
