import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    isContactsMenuHidden() {
      return this.currentUser.hide_contacts_menu;
    },
    allowedAccessibleMenuItems() {
      if (this.isContactsMenuHidden)
        return this.accessibleMenuItems.filter(this.isItemAllowed);

      return this.accessibleMenuItems;
    },
  },
  methods: {
    isItemAllowed(item) {
      return item.toStateName !== 'contacts_dashboard';
    },
  },
};
