const languages = [
  {
    name: 'Abecásio',
    id: 'ab',
  },
  {
    name: 'Afar',
    id: 'aa',
  },
  {
    name: 'Africâner',
    id: 'af',
  },
  {
    name: 'Akan',
    id: 'ak',
  },
  {
    name: 'Albanês',
    id: 'sq',
  },
  {
    name: 'Amárico',
    id: 'am',
  },
  {
    name: 'Árabe',
    id: 'ar',
  },
  {
    name: 'Aragonês',
    id: 'an',
  },
  {
    name: 'Armênio',
    id: 'hy',
  },
  {
    name: 'Assamês',
    id: 'as',
  },
  {
    name: 'Avaric',
    id: 'av',
  },
  {
    name: 'Avéstico',
    id: 'ae',
  },
  {
    name: 'Aimará',
    id: 'ay',
  },
  {
    name: 'Azerbaijano',
    id: 'az',
  },
  {
    name: 'Bambara',
    id: 'bm',
  },
  {
    name: 'Bashkir',
    id: 'ba',
  },
  {
    name: 'Basco',
    id: 'eu',
  },
  {
    name: 'Bielorrusso',
    id: 'be',
  },
  {
    name: 'Bengali',
    id: 'bn',
  },
  {
    name: 'Bislama',
    id: 'bi',
  },
  {
    name: 'Bósnio',
    id: 'bs',
  },
  {
    name: 'Bretão',
    id: 'br',
  },
  {
    name: 'Búlgaro',
    id: 'bg',
  },
  {
    name: 'Birmanês',
    id: 'my',
  },
  {
    name: 'Catalão',
    id: 'ca',
  },
  {
    name: 'Chamorro',
    id: 'ch',
  },
  {
    name: 'Checheno',
    id: 'ce',
  },
  {
    name: 'Chichewa',
    id: 'ny',
  },
  {
    name: 'Chinês',
    id: 'zh',
  },
  {
    name: 'Eslavo Eclesiástico',
    id: 'cu',
  },
  {
    name: 'Chuvash',
    id: 'cv',
  },
  {
    name: 'Córnico',
    id: 'kw',
  },
  {
    name: 'Corso',
    id: 'co',
  },
  {
    name: 'Cree',
    id: 'cr',
  },
  {
    name: 'Croata',
    id: 'hr',
  },
  {
    name: 'Checo',
    id: 'cs',
  },
  {
    name: 'Dinamarquês',
    id: 'da',
  },
  {
    name: 'Divehi',
    id: 'dv',
  },
  {
    name: 'Holandês',
    id: 'nl',
  },
  {
    name: 'Dzongkha',
    id: 'dz',
  },
  {
    name: 'Inglês',
    id: 'en',
  },
  {
    name: 'Esperanto',
    id: 'eo',
  },
  {
    name: 'Estoniano',
    id: 'et',
  },
  {
    name: 'Ewe',
    id: 'ee',
  },
  {
    name: 'Feroês',
    id: 'fo',
  },
  {
    name: 'Fijiano',
    id: 'fj',
  },
  {
    name: 'Finlandês',
    id: 'fi',
  },
  {
    name: 'Francês',
    id: 'fr',
  },
  {
    name: 'Frísio Ocidental',
    id: 'fy',
  },
  {
    name: 'Fula',
    id: 'ff',
  },
  {
    name: 'Gaélico',
    id: 'gd',
  },
  {
    name: 'Galego',
    id: 'gl',
  },
  {
    name: 'Ganda',
    id: 'lg',
  },
  {
    name: 'Georgiano',
    id: 'ka',
  },
  {
    name: 'Alemão',
    id: 'de',
  },
  {
    name: 'Grego',
    id: 'el',
  },
  {
    name: 'Kalaallisut',
    id: 'kl',
  },
  {
    name: 'Guarani',
    id: 'gn',
  },
  {
    name: 'Gujarati',
    id: 'gu',
  },
  {
    name: 'Haitiano',
    id: 'ht',
  },
  {
    name: 'Hauçá',
    id: 'ha',
  },
  {
    name: 'Hebraico',
    id: 'he',
  },
  {
    name: 'Herero',
    id: 'hz',
  },
  {
    name: 'Hindi',
    id: 'hi',
  },
  {
    name: 'Hiri Motu',
    id: 'ho',
  },
  {
    name: 'Húngaro',
    id: 'hu',
  },
  {
    name: 'Islandês',
    id: 'is',
  },
  {
    name: 'Ido',
    id: 'io',
  },
  {
    name: 'Igbo',
    id: 'ig',
  },
  {
    name: 'Indonésio',
    id: 'id',
  },
  {
    name: 'Interlíngua',
    id: 'ia',
  },
  {
    name: 'Interlingue',
    id: 'ie',
  },
  {
    name: 'Inuktitut',
    id: 'iu',
  },
  {
    name: 'Inupiaq',
    id: 'ik',
  },
  {
    name: 'Irlandês',
    id: 'ga',
  },
  {
    name: 'Italiano',
    id: 'it',
  },
  {
    name: 'Japonês',
    id: 'ja',
  },
  {
    name: 'Javanês',
    id: 'jv',
  },
  {
    name: 'Canarim',
    id: 'kn',
  },
  {
    name: 'Kanuri',
    id: 'kr',
  },
  {
    name: 'Caxemira',
    id: 'ks',
  },
  {
    name: 'Cazaque',
    id: 'kk',
  },
  {
    name: 'Khmer Central',
    id: 'km',
  },
  {
    name: 'Kikuyu',
    id: 'ki',
  },
  {
    name: 'Kinyarwanda',
    id: 'rw',
  },
  {
    name: 'Quirguiz',
    id: 'ky',
  },
  {
    name: 'Komi',
    id: 'kv',
  },
  {
    name: 'Kongo',
    id: 'kg',
  },
  {
    name: 'Coreano',
    id: 'ko',
  },
  {
    name: 'Kuanyama',
    id: 'kj',
  },
  {
    name: 'Curdo',
    id: 'ku',
  },
  {
    name: 'Lao',
    id: 'lo',
  },
  {
    name: 'Latim',
    id: 'la',
  },
  {
    name: 'Letão',
    id: 'lv',
  },
  {
    name: 'Limburguês',
    id: 'li',
  },
  {
    name: 'Lingala',
    id: 'ln',
  },
  {
    name: 'Lituano',
    id: 'lt',
  },
  {
    name: 'Luba-Katanga',
    id: 'lu',
  },
  {
    name: 'Luxemburguês',
    id: 'lb',
  },
  {
    name: 'Macedônio',
    id: 'mk',
  },
  {
    name: 'Malgaxe',
    id: 'mg',
  },
  {
    name: 'Malaio',
    id: 'ms',
  },
  {
    name: 'Malaiala',
    id: 'ml',
  },
  {
    name: 'Maltês',
    id: 'mt',
  },
  {
    name: 'Manx',
    id: 'gv',
  },
  {
    name: 'Maori',
    id: 'mi',
  },
  {
    name: 'Marata',
    id: 'mr',
  },
  {
    name: 'Marshalês',
    id: 'mh',
  },
  {
    name: 'Mongol',
    id: 'mn',
  },
  {
    name: 'Nauruano',
    id: 'na',
  },
  {
    name: 'Navajo',
    id: 'nv',
  },
  {
    name: 'Ndebele do Norte',
    id: 'nd',
  },
  {
    name: 'Ndebele do Sul',
    id: 'nr',
  },
  {
    name: 'Ndonga',
    id: 'ng',
  },
  {
    name: 'Nepalês',
    id: 'ne',
  },
  {
    name: 'Norueguês',
    id: 'no',
  },
  {
    name: 'Bokmål Norueguês',
    id: 'nb',
  },
  {
    name: 'Nynorsk Norueguês',
    id: 'nn',
  },
  {
    name: 'Sichuan Yi',
    id: 'ii',
  },
  {
    name: 'Occitano',
    id: 'oc',
  },
  {
    name: 'Ojibwa',
    id: 'oj',
  },
  {
    name: 'Oriá',
    id: 'or',
  },
  {
    name: 'Oromo',
    id: 'om',
  },
  {
    name: 'Ossétio',
    id: 'os',
  },
  {
    name: 'Pali',
    id: 'pi',
  },
  {
    name: 'Pashto, Pushto',
    id: 'ps',
  },
  {
    name: 'Persa',
    id: 'fa',
  },
  {
    name: 'Polonês',
    id: 'pl',
  },
  {
    name: 'Português',
    id: 'pt',
  },
  {
    name: 'Punjabi',
    id: 'pa',
  },
  {
    name: 'Quíchua',
    id: 'qu',
  },
  {
    name: 'Romeno',
    id: 'ro',
  },
  {
    name: 'Romanche',
    id: 'rm',
  },
  {
    name: 'Rundi',
    id: 'rn',
  },
  {
    name: 'Russo',
    id: 'ru',
  },
  {
    name: 'Sami do Norte',
    id: 'se',
  },
  {
    name: 'Samoano',
    id: 'sm',
  },
  {
    name: 'Sango',
    id: 'sg',
  },
  {
    name: 'Sânscrito',
    id: 'sa',
  },
  {
    name: 'Sardo',
    id: 'sc',
  },
  {
    name: 'Sérvio',
    id: 'sr',
  },
  {
    name: 'Shona',
    id: 'sn',
  },
  {
    name: 'Sindi',
    id: 'sd',
  },
  {
    name: 'Cingalês',
    id: 'si',
  },
  {
    name: 'Eslovaco',
    id: 'sk',
  },
  {
    name: 'Esloveno',
    id: 'sl',
  },
  {
    name: 'Somali',
    id: 'so',
  },
  {
    name: 'Soto do Sul',
    id: 'st',
  },
  {
    name: 'Espanhol',
    id: 'es',
  },
  {
    name: 'Sundanês',
    id: 'su',
  },
  {
    name: 'Suaíli',
    id: 'sw',
  },
  {
    name: 'Swati',
    id: 'ss',
  },
  {
    name: 'Sueco',
    id: 'sv',
  },
  {
    name: 'Tagalo',
    id: 'tl',
  },
  {
    name: 'Taitiano',
    id: 'ty',
  },
  {
    name: 'Tadjique',
    id: 'tg',
  },
  {
    name: 'Tâmil',
    id: 'ta',
  },
  {
    name: 'Tártaro',
    id: 'tt',
  },
  {
    name: 'Telugo',
    id: 'te',
  },
  {
    name: 'Tailandês',
    id: 'th',
  },
  {
    name: 'Tibetano',
    id: 'bo',
  },
  {
    name: 'Tigrínia',
    id: 'ti',
  },
  {
    name: 'Tonga',
    id: 'to',
  },
  {
    name: 'Tsonga',
    id: 'ts',
  },
  {
    name: 'Tswana',
    id: 'tn',
  },
  {
    name: 'Turco',
    id: 'tr',
  },
  {
    name: 'Turcomeno',
    id: 'tk',
  },
  {
    name: 'Twi',
    id: 'tw',
  },
  {
    name: 'Uigur',
    id: 'ug',
  },
  {
    name: 'Ucraniano',
    id: 'uk',
  },
  {
    name: 'Urdu',
    id: 'ur',
  },
  {
    name: 'Uzbeque',
    id: 'uz',
  },
  {
    name: 'Venda',
    id: 've',
  },
  {
    name: 'Vietnamita',
    id: 'vi',
  },
  {
    name: 'Volapuque',
    id: 'vo',
  },
  {
    name: 'Valão',
    id: 'wa',
  },
  {
    name: 'Galês',
    id: 'cy',
  },
  {
    name: 'Wolof',
    id: 'wo',
  },
  {
    name: 'Xhosa',
    id: 'xh',
  },
  {
    name: 'Ídiche',
    id: 'yi',
  },
  {
    name: 'Iorubá',
    id: 'yo',
  },
  {
    name: 'Zhuang, Chuang',
    id: 'za',
  },
  {
    name: 'Zulu',
    id: 'zu',
  },
];

export const getLanguageName = (languageCode = '') => {
  const languageObj =
    languages.find(language => language.id === languageCode) || {};
  return languageObj.name || '';
};

export const getLanguageDirection = (languageCode = '') => {
  const rtlLanguageIds = ['ar', 'as', 'fa', 'he', 'ku', 'ur'];
  return rtlLanguageIds.includes(languageCode);
};

export default languages;
